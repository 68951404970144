<template>
  <div class="pagination">
    <el-pagination
      @size-change="pagingSizeChange"
      @current-change="pagingNumChange"
      :current-page="pagingParams.pageNum"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pagingParams.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: Number,
    pagingParams: Object,
  },
  methods: {
    pagingSizeChange(val) {
      console.log(val);
      this.$emit("pagingSizeChange", val);
    },
    pagingNumChange(val) {
      this.$emit("pagingNumChange", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-pagination {
  margin: 15px 0;
}
</style>
