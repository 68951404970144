<template>
  <div class="healthIQ">
    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <el-col class="tabbar">
          <div class="">
            <el-input size="medium" class="demo-input-label" placeholder="请输入公司名称" maxlength="10" v-model="companyName" clearable>
            </el-input>
            <el-input size="medium" class="demo-input-label" placeholder="请输入公司编码" maxlength="10" v-model="companyNo" clearable>
            </el-input>
            <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-search" @click="searchClick">搜索</el-button>

            <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-plus" @click="addInfo()">添加</el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <div>
      <el-table :data="records" :height="tableHeight" border style="width: 98%">
        <el-table-column type="index" width="50">
          <template slot="header" slot-scope="{}"> 序号 </template>
        </el-table-column>
        <el-table-column prop="id" label="订单编号" width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="name" label="订单名称" width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="companyName" label="公司" width="150" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="sassServiceDictName" label="活动类型" width="150" sortable>
        </el-table-column>
        <el-table-column prop="startTime" label="开始时间" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="endTime" label="结束时间" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="addInfo(scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeById(scope.row)">删除</el-button>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="examineInfoClick(scope.row.id)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <!-- 分页区域 -->

      <pagination :pagingParams="params" :total="total" @pagingSizeChange="handleSizeChange" @pagingNumChange="handleCurrentChange"></pagination>
    </div>
    <!-- 修改用户的对话框 -->
    <el-dialog title="订单修改" :visible.sync="setRoleDialogVisible" width="50%" @close="editDialogClosed">
      <el-form :model="saveInfoParams" ref="editFormRef" label-width="70px">
        <el-form-item label="公司编号">
          <!-- <el-input v-model="saveInfoParams.companyNo"></el-input> -->
          <el-select v-model="saveInfoParams.companyNo" placeholder="请选择">
            <el-option v-for="item in companyList" :key="item.companyNo" :label="item.companyName" :value="item.companyNo">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker v-model="timevalue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerBeginDateBefore" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="selectTime">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="活动id">
          <el-cascader v-model="projectId" :options="dictList" :props="props"></el-cascader>
        </el-form-item>
        <el-form-item label="题目数量">
          <el-input v-model="nodeOrder" :disabled="!saveInfoParams.sassServiceDictNo" type="Number" placeholder="请输入数量"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRoleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveInfo">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改用户的对话框 -->
    <el-dialog title="订单信息" :visible.sync="isExamineInfo" width="50%" @close="isExamineInfo = false">
      <el-form :model="examineInfo" label-width="70px">
        <el-form-item label="开始时间">
          <span>{{ examineInfo.createTime }}</span>
        </el-form-item>

        <el-form-item label="题目数量">
          <span>{{ examineInfo.questionNum }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isExamineInfo = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "@/components/common/pagination/Index.vue";
export default {
  name: "AdminDemoIndex",
  components: {
    pagination,
  },

  data() {
    return {
      tableHeight: window.innerHeight - 350,
      params: { pageNum: 1, pageSize: 10, companyName: "", companyNo: "" },
      companyName: "",
      companyNo: "",
      records: [],
      total: 0,
      isIndeterminate: false,
      timevalue: "",
      projectId: "",
      nodeOrder: "",
      saveInfoParams: {
        createUser: "1",
        companyNo: "",
        sassServiceDictNo: "",
        endTime: "",
        nodeOrder: {
          questionNum: 0,
        },
        startTime: "",
      },
      props: {
        label: "name",
        value: "id",
      },
      dictList: [],
      // 控制分配角色对话框的显示与隐藏
      setRoleDialogVisible: false,
      minDate: "",
      maxDate: "",
      examineInfo: {},
      isExamineInfo: false,
    };
  },
  watch: {
    projectId(newValue, oldValue) {
      console.log(newValue);
      const id1 = newValue[newValue.length - 1];
      const id2 = oldValue[oldValue.length - 1];
      if (id1 !== id2) {
        this.saveInfoParams.sassServiceDictNo = id1;
      }
    },
    nodeOrder(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.saveInfoParams.nodeOrder = {
          questionNum: newValue,
        };
      }
    },
    params: {
      deep: true, //深度监听设置为 true
      handler: function () {
        this.findList();
      },
    },
  },

  computed: {},
  mounted() {
    this.findList();
  },
  methods: {
    async examineInfoClick(ID) {
      this.isExamineInfo = true;
      const res = await this.$API.sassOrderHealthIQInfo({ id: ID });
      if (res.status == 200) {
        this.examineInfo = res.data;
      }
    },
    selectTime() {
      this.saveInfoParams.startTime = `${this.timevalue[0]} 00:00:00`;
      this.saveInfoParams.endTime = `${this.timevalue[1]} 23:59:59`;
    },
    async getTagsList() {
      const res = await this.$API.getDictList({ dictType: 3 });
      if (res.status !== 200) return "";
      this.dictList = res.data;
    },
    setRoleDialogClosed() {},
    async saveInfo() {
      const params = JSON.parse(JSON.stringify(this.saveInfoParams));
      params.createUser = "1";
      const res = await this.$API.sassOrderSave(params);
      if (res.status == 200) {
        this.setRoleDialogVisible = false;
        this.$message.success(res.msg);
        this.params.pageNum = 1;
        this.params.companyName = "";
      }
    },
    async removeById(item) {
      const { id, sassServiceDictNo } = item;
      let params = {
        orderId: id,
        sassServiceDictNo,
      };
      // 弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        "此操作将永久删除该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }

      const res = await this.$API.sassOrderRemove(params);

      if (res.status !== 200) {
        return this.$message.error("删除失败！");
      }
      this.findList();
      this.$message.success("删除成功！");
    },
    async addInfo(item = {}) {
      const companyList = await this.findcompanyList();
      if (companyList.length == 0) {
        return this.$message.info("请先添加公司");
      }

      this.getTagsList();
      this.companyList = companyList;
      this.setRoleDialogVisible = true;
      if (item) {
        this.saveInfoParams = item;
        if (item.startTime) {
          this.timevalue = [
            item.startTime.split(" ")[0],
            item.endTime.split(" ")[0],
          ];
        }
      }
    },
    searchClick() {
      this.params.pageNum = 1;
      this.params.companyName = this.companyName;
      this.params.companyNo = this.companyNo;
    },
    handleSizeChange(newSize) {
      this.params.pageSize = newSize;
    },
    handleCurrentChange(newPage) {
      this.params.pageNum = newPage;
    },
    handleCheckAllChange() {},
    async findList() {
      const res = await this.$API.sassOrderQuery(this.params);
      if (res.status == 200) {
        const { records, total } = res.data;
        this.records = records;
        this.total = +total;
        console.log(this.total);
      }
    },

    async findcompanyList() {
      const res = await this.$API.sCompanyQuery();
      if (res.status == 200) {
        console.log(res.data);
        return res.data;
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.healthIQ {
  width: 100%;
  background: #fff;
}

.el-cascader {
  width: 100%;
}

.pagination {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-breadcrumb {
  margin-bottom: 20px;
}
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}
.el-pagination {
  width: 1000px;
  margin: 20px auto;
}
.el-tag {
  margin: 7px;
}
</style>
